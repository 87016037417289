import React from 'react'
import {
    Box,
    Container,
    Heading,
    SimpleGrid,
    Icon,
    Text,
    Stack,
    HStack,
    VStack,
    Image
} from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';
import styles from '../styles/questions.module.css';
import awrlogo from '../assets/awrlogo.svg'

const Questions =(props)=>{
    const allQuestions=[
        {
            question:'What language are these Bible study meetings in?',
        answer: 'All topics covered are in English.'},
        {
            question: 'How much does it cost to enroll in a Bible study?',
            answer: 'Signing up for our Bible study meetings is absolutely free!'
        },
        {
            question: "Can I sign up for just one of the topics mentioned?",
            answer:"When you enroll, you are signing up for a complete series that has 9 topics sequentially knit together. So we encourage you to attend all topics."
        }
    ]
    

    return (
        <Box className={styles.questionsContainer}>
            <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'} mt={10}>
                <Heading fontSize={{ base: '3xl', xl: '4xl' }} fontFamily={'BioRhyme'}>Questions about our Bible Studies</Heading>
                <Text color={'gray.700'} fontSize={'xl'} >
                    Dig deeper into the Bible through a supportive small group
                </Text>
            </Stack>

            <Container maxW={'6xl'} mt={10} mb={14}>
                <SimpleGrid columns={{ base: 1 }} spacing={10}>
                    {allQuestions.map((question, index) => (
                        <HStack key={index} align={'top'}>
                            <Box color={'blue.400'} px={4}>
                                <Icon as={QuestionIcon} />
                            </Box>
                            <VStack align={'start'}>
                                <Text fontWeight={600} fontSize={{md:'xl'}}>{question.question}</Text>
                                <Text color={'gray.600'} fontSize={{ md: 'lg' }}>{question.answer}</Text>
                            </VStack>
                        </HStack>
                    ))}
                </SimpleGrid>
            </Container>
            <HStack backgroundColor={'whitesmoke'} width={'100%'} height={{ base: 'auto', md: '10rem' }} padding={'3rem'} justifyContent={'center'}>
                <VStack maxW={{ base: 'auto', md: '15%' }} mr={{ base: '10%', md: '20%' }}>
                    <Image src={awrlogo} alt='logo awr' width={'auto'} height={'auto'}/>

                </VStack>
                <VStack>
                    <Text color={'gray.700'} fontSize={{ base: 'sm', md: 'md' }}>
                        2023 Adventist World Radio.<br /> Center for Digital Evangelism. <br />All rights reserved.
                    </Text>
                </VStack>
            </HStack>
    </Box>
        
        )
  }
export default Questions