import React, { useState,useEffect,useRef } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useLocation,useNavigate } from 'react-router';
import {Link } from 'react-router-dom';
import {
    Stack,
    Box,
    Image,Button,
    VStack,
    useBreakpointValue,
    IconButton,
    HStack
} from '@chakra-ui/react';
import {
    Alert
  } from '@chakra-ui/react'
  //import { Link } from '@chakra-ui/react';
import video1 from './assets/video1.mp4';
import screenshot from './assets/screenshot.jpg'
import './App.css';
import Questions from './components/questions';
import getStudies from './utils/getStudies';
import directusUrl from './utils/urlString';
import { Spinner } from '@chakra-ui/react';
import { Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent,
  DrawerCloseButton, Flex, Text
} from "@chakra-ui/react";
import {HamburgerIcon} from '@chakra-ui/icons';
import StudiesSection from './components/studiesSection';
import Testimony from './components/testimony';
import { Heading } from '@chakra-ui/react'
import loadTopics from './utils/loadTopics';
import Register from './utils/register';

function Startpage(props) {
    const [studies, setStudies] = useState([]);
    const [data, setData] = useState([]);
    const [iconFeaturedSelected, setIconFeaturedSelected] = useState();
    const [showTopics, setShowTopics] = useState(false);
    const [highlighted, setHighlighted] = useState();
    const [studySelected, setStudySelected] = useState(); //a string
    const location = useLocation();
    const navigate=useNavigate()

    useEffect(() => { getLocation() }, [])

    const getLocation = () => {
        switch (location.pathname) {
            case '/path-of-peace':
                setStudySelected('path')
                topicsLoader(); break;
            case '/conflict':
                setStudySelected('conflict')
                topicsLoader(); break;
            case '/encounter':
                setStudySelected('encounter');
                topicsLoader(); break;
            case "/"://Hotfix for encounter on home
                setStudySelected('encounter');
                topicsLoader();
                return navigate('/encounter');
            default:
                return false
        }
        
    }
    useEffect(() => {
        console.log(studySelected,location.pathname);
    }, [studySelected])

    const [menu, setMenu] = useState([
        {
            menuName: 'About us',
            link: 'About'
        }, {
            menuName: "Register",
            link: "Register"
        },
        {
            menuName: "Testimonies",
            link:'Testimony'
        },
        {
            menuName: "FAQ",
            link:'Faq'
        }
    ]);
            
    const topicImageUrl = directusUrl() + "assets/";
    const ref = useRef(); //REF for down arrow button
    const refTopic = useRef(); // ref for topic clicked
    const refFaq = useRef(); // ref for top banner
    const refTestimony = useRef();
    const arrowButton = <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
    </svg>

    var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    const showIcons = () => {
        if (data != null) {
            // FEATURED ICONS
            return <Stack direction={'row'} spacing={{ md: 16 }} w={'100%'} justifyContent={{ base: 'space-between', md: 'center' }} className='home'>
                {
                    data.map((topic, index) => {
                        return <Box key={index} onClick={() => { searchStudy(topic.topic_id); setIconFeaturedSelected(topic) }}>
                            <Image src={topicImageUrl + topic.topic_icon} alt='icon image' boxSize={{ base: '55px', md: '83px', xl: '120px' }}
                                objectFit='cover' margin={{ base: 'auto' }} onClick={() => { }} cursor={'pointer'} />
                            <Text
                                color={'white'}
                                fontSize={{ base: 'sm', md: 'xl' }}
                                fontFamily={'Epilogue'}
                                marginTop={'auto'}
                                mt={'7px'}
                                className='text-home'
                            >
                                {topic.name}
                            </Text>
                        </Box>
                    })
                }
            </Stack>
        } else {
            console.log("NULL")
            return false
        }
    }
    //Search the study the featured icon topic belongs to. Not used now.
    const searchStudy = (id) => {
        studies.forEach(study => {
            study.topics.forEach(topic => {
                if (topic.gw_Topic_topic_id === id) {
                    let foundId = study
                    setStudySelected(foundId);
                    setShowTopics(true);
                    scrollToFocused()
                }
            })
        })
    }

    //UseEffect for get featured
    useEffect(() => {
        // HOOK TO CALL DIRECTUS AT FIRST RENDER
        //FEATURED TOPICS
        try {
            fetch(directusUrl() + "items/gw_Topic?filter[featured][_eq]=true", requestOptions)
                .then(response => response.json())
                .then(result => {
                    //console.log(result);
                    setData(result.data)
                })
                .catch(error => console.log('error getting featured topics', error));
            // resJson = resJson.map(e => e.results[0].name.first)

        } catch (err) {
            console.log(err)
        }
    }, []);

    //CALL TO API GET TOPICS
    //useEffect for get studies
    useEffect(() => {
        let studiesReceived;
        getStudies().then(data => { //receive array of studies
            //console.log("DATA", data);
            studiesReceived = data;
            setStudies(studiesReceived)
        })
    }, []);

    //Use effect to load topics
    const topicsLoader =() => {
        if (studySelected != null) { 
            console.log("Study selected",studySelected)
            let myStudy = studies.filter((e) => e.code === studySelected)[0];
            console.log("My study?",myStudy.topics)
            loadTopics(myStudy.topics).then(response => response.json()).then(result => console.log(result))
        }
    }

    //Show Studies. Verifies if is selected according to pathname. By code.
    const showStudyComponent = () => {
        if (studySelected != null) {
            return ( 
                studies.map((study, index) => {
                    return study.code === studySelected ?
                        <StudiesSection name={study.name} studyCover={study.studyCover} topics={study.topics}
                            key={study.id} studyId={study.id} difficulty={study.difficulty} comment={ study.series_description} />
                        :
                        false
                })
            )
            //No study selected, url is wrong or empty, so show the register component alone.
        } else {
            return <Register studyId={studySelected} />
        }
    }

    const scroller = () => {
        ref.current.scrollIntoView({ behavior: 'smooth' })
    }
    const scrollerFaq = (anItem) => {
        switch (anItem) {
            case "About":
                refFaq.current.scrollIntoView({ behavior: 'smooth' }); break;
            case "Testimony":
                refTestimony.current.scrollIntoView({ behavior: 'smooth' }); break;
            case "Register":
                ref.current.scrollIntoView({ behavior: 'smooth' }); break;
            case "Faq":
                refFaq.current.scrollIntoView({ behavior: 'smooth' }); break;
        }
    }

    //not used
    const selectedStudyFromBanner = (aStudy) => {
        setStudySelected(studies[aStudy.index])
        console.log("selected study", studies[aStudy.index]);
        scrollToFocused();
        setShowTopics(true) 
    }

    const scrollToFocused=()=>{
        refTopic.current.scrollIntoView({behavior:'smooth'});
        setShowTopics(true)
    }
    const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()
    
    return (
        <Flex
            w={'full'}
            direction={'column'}
            backgroundSize={'cover'}
            backgroundPosition={'center center'}
            className={'complete-flex-study'}>
            <Box id="header">
                <Flex
                    className='navBar'
                    w="100%"
                    px="6"
                    py="2"
                    align="center"
                    justify={{base:'space-between',md:"space-evenly"}}
                >
                    <Image src="https://groups.awr.org/static/media/awrlogo.41ce62ab5198ce3a77e238fe4da03488.svg" h="50px" />
                    
                    {/* // Nav Items */}
                    <HStack as="nav" spacing="5" display={{base:'none',md:'block'}}>
                        {menu.map((item, i) => (
                            <Link key={i} >
                                <Button variant="nav"
                                    onClick={() => {
                                        console.log("item", item);
                                        item.index!=null ? (selectedStudyFromBanner(item)) : scrollerFaq(item.link)
                                    }}
                                > {item.menuName}  </Button>
                            </Link>
                        ))}
                    </HStack>

                    {/* // Hamburguer */}
                    <HStack display={{md:'none'}}>
                        <IconButton colorScheme='teal' onClick={onOpen} icon={<HamburgerIcon />}>
                            Open
                        </IconButton>
                    </HStack>
                    
                </Flex>
            </Box>
            {/* DRAVER ONLY OPEN ON HAMBURGUER ICON CLICKED*/ }
                <Drawer
                    isOpen={isOpen}
                    placement='right'
                    onClose={onClose}
                    finalFocusRef={btnRef}
                >
                    <DrawerOverlay />
                    <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Study the Bible now</DrawerHeader>

                    <DrawerBody>
                        <VStack as="nav" spacing="5" >
                        {menu.map((item, i) => (
                            <Link key={i}>
                                <Button variant="nav" onClick={() => {
                                    console.log("item", item);
                                    item.index != null ? (selectedStudyFromBanner(item)) : scrollerFaq(item.link);
                                    onClose()
                                }}> {item.menuName} </Button>
                            </Link>
                        ))}
                    </VStack>
                    </DrawerBody>
                    </DrawerContent>
                </Drawer>
            {highlighted ? (<Alert backgroundColor={'gray.50'} justifyContent={'center'} variant={'left-accent'} borderTop={'1px solid lightyellow'}>
                <Link onClick={() => { setStudySelected(highlighted); scrollToFocused()}}  textAlign={'center'}>
                       <Text as='span' fontWeight={'500'}>
                         Bible study <i>{highlighted ? highlighted.name : <Spinner />}</i> is starting soon. 
                        </Text>
                        <Text as='b' color={'red.600'}> Click here to enroll!</Text>
                    </Link>
                </Alert>) : false}
            <VStack
                w={'full'}
                height={'90vh'}
                justify={'space-evenly'}
                px={useBreakpointValue({ base: 4, md: 8 })}
                className={'main-text-icons'}
            >
                <video className='videoTag' preload='metadata' poster={screenshot} autoPlay loop muted id='video'>
                    <source src={video1} type='video/mp4' />
                </video>
                
                <Stack w={'100%'} maxW={'4xl'} align={'center'} spacing={useBreakpointValue({ base: 12, md: 18, xl: 28 })} filter={'drop-shadow(2px 2px 6px rgb(10, 10, 10))' }>
                    <Text
                        fontWeight={700}
                        lineHeight={1.2}
                        fontSize={{ base: '6xl', md:'7xl',xl:'8xl'}}
                        fontFamily={'BioRhyme'}
                        textTransform={'uppercase'}
                        className='text-home home'
                    >
                        Study the Bible now
                    </Text>
                    <Text
                        fontSize={{ base: '2xl', md: '3xl', xl: '4xl' }}
                        fontFamily={'Epilogue'}
                        className='text-home home'
                    >Real People, Real Conversations. <br />
                        Join our interactive Bible study now.
                    </Text>
                    {data ? showIcons() : <h1>loading icons</h1>}                    
                </Stack>
                <IconButton className={'help-button'} onClick={() => { scroller() }} icon={arrowButton} variant='ghost' aria-label="scroll to study"/>
            </VStack>
            
            <Stack className='stack-for-background' ref={ref}>
                <Stack className='studies-displayer' alignItems={'center'} direction={{ base: 'column' }} sx={{margin:["2","2","10"]} }>
                    <Heading as='h2' size='2xl' py={6 }>
                        {studySelected ? "Our study for you" : "Register now"}
                    </Heading>
                    {
                        // STUDY SERIES - ALL TOPICS IMAGES
                       showStudyComponent()                        
                    }
                </Stack>
                              
            </Stack>
            <span className={'for-testimony'} ref={refTestimony }></span>
            <Testimony />
            <span className={'for-questions'} ref={refFaq}></span>
            <Questions />

    </Flex>
        
    );
}
export default Startpage;
